import React from 'react';

//Components
import { Container } from '@components/Utilities/Container/Container';

//Styles
import styles from './CookiePolicy.module.scss';

export const CookiePolicy = () => {
    return (
        <section className={styles.cookiePolicy}>
            <Container>
                <div id="cookiefirst-policy-page"></div>
                <div>
                    This cookie policy has been created and updated by{' '}
                    <a href="https://cookiefirst.com">CookieFirst.com</a>.
                </div>
            </Container>
        </section>
    );
};
